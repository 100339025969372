







































































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default defineComponent({
  name: 'uOttawa1321Lab1TLCPlate1',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        language: 'en',
      },
      TlcPlateA: [] as File[],
      questions: [
        {
          en: 'Please use the upload field below to upload the drawing(s) of your TLC plate(s) for Part A of your experiment. These can be prepared with any software of your choice but should be clearly labelled.',
          fr: "Veuillez utiliser le champ de téléchargement ci-dessous pour télécharger le(s) dessin(s) de votre plaque(s) de CCM pour la partie A de votre expérience. Ces dessins peuvent être préparés avec n'importe quel logiciel de votre choix, mais ils doivent être clairement étiquetés.",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
  computed: {
    attachments(): File[] {
      return [...this.TlcPlateA];
    },
  },
});
